import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 250px;
  width: 100%;

  &:active {
    background-color: #333;
    }

  &:disabled {
    background-color: #ccc;
    }
`;

export const GalleryButton = styled.button`
  padding: 10px;
  font-size: 16px;
  color: black;
  background-color: lightblue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;
  width: 100%;

  &:active {
    background-color: #333;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const GreenButton = styled.button`
  padding: 10px;
  font-size: 16px;
  color: black;
  background-color: lightgreen;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;
  width: 100%;

  &:active {
    background-color: #333;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const RedButton = styled.button`
  padding: 10px;
  font-size: 16px;
  color: black;
  background-color: lightcoral;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;
  width: 100%;

  &:active {
    background-color: #333;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const HeaderButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:active {
    background-color: #333;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const FileInput = styled.input`
  margin-top: 10px;

  &::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  &::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  &::file-selector-button:active {
    background-color: #e5e7eb;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
  align-items: center;
`;

export const InlineFlex = styled.div`
  display: flex;
  gap: 10px;
`;

export const ImageGrid = styled.div`
  display: grid;
  object-fit: cover;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 20px;
  background-color: #f0f2f5;
  margin-bottom: 10px;
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background: ${props => props.selected ? 'rgba(0, 255, 0, 0.3)' : 'none'}; // Green overlay for selected images
`;
export const SelectedIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: green;
  font-size: 24px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5); // Optional: adds a slight background to the icon for better visibility
  border-radius: 50%;
  padding: 5px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  background:
    repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #ccc 10px,
    #ccc 20px
  );
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  border: 1px dashed black;
  opacity: 1;
  transition-opacity: 0.5s;
  background:
    repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #ccc 10px,
    #ccc 20px
  );
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px;
    background-color: black;
    border-radius: 10px;
`;

export const ImagePreview = styled.img`
  max-width: 100vw !important;
  max-height: 90vh !important;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid black;
`;

export const CustomAlert = ({ message, onClose }) => {
  return (
    <AlertOverlay>
      <AlertBox>
        <AlertMessage>{message}</AlertMessage>
        <AlertButton onClick={onClose}>OK</AlertButton>
      </AlertBox>
    </AlertOverlay>
  );
};

export const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const AlertBox = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const AlertMessage = styled.p`
  margin: 0 0 20px;
`;

export const AlertButton = styled.button`
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Modal = ({ children, onClose }) => {
  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleClickOutside}>
      {children}
    </div>
  );
};

export const ModalContent = ({ children }) => {
  return <div className="modal-content">{children}</div>;
};
