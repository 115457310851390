import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './am-logo.svg';
import { Form, FileInput, Button, InlineFlex, GalleryButton, Modal, ModalContent } from './Components.js';

axios.defaults.withCredentials = true;

function App() {
    const [files, setFiles] = useState([]);
    const [totalProgress, setTotalProgress] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fileError, setFileError] = useState('');
    const [totalSize, setTotalSize] = useState(0);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);  // New state for button disable/enable

    useEffect(() => {
        const verifySession = async () => {
            try {
                const response = await axios.get('https://backend.am.markoshub.com:1337/api/protected');
                if (response.status === 200) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.log('Session verification failed:', error);
            }
        };
        verifySession();
    }, []);

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://backend.am.markoshub.com:1337/api/verify-password', { password });
            if (response.status === 200) {
                setIsAuthenticated(true);
                setErrorMessage('');
            }
        } catch (error) {
            if (error.response.status === 429) {
                setErrorMessage('Too many failed attempts. Please try again later.');
            } else if (error.response.status === 401) {
                setErrorMessage('Incorrect password.');
            } else {
                setErrorMessage('An unknown error occurred.');
            }
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (files.length === 0) {
            return;
        }

        let totalLoaded = 0;

        const uploadPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('file', file);

                const config = {
                    onUploadProgress: (progressEvent) => {
                        totalLoaded += progressEvent.loaded - (file.loaded || 0);
                        file.loaded = progressEvent.loaded;
                        const progress = Math.round((totalLoaded * 100) / totalSize);
                        setTotalProgress(progress);
                    }
                };

                axios.post('https://backend.am.markoshub.com:1337/api/upload', formData, config)
                    .then((response) => {
                        if (response.status === 200) {
                            resolve(response);
                        } else {
                            reject(new Error('Upload failed'));
                        }
                    })
                    .catch((error) => reject(error));
            });
        });

        try {
            await Promise.all(uploadPromises);
            setFiles([]);
            setTotalProgress(100);
            document.getElementById('fileInput').value = '';
            setShowSuccessPopup(true); // Show pop-up after all uploads complete
        } catch (error) {
            alert('Nastala chyba, skúste obnoviť stránku.');
            console.error('Error uploading files:', error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const validFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'];

        const invalidFiles = selectedFiles.filter(file => !validFileTypes.includes(file.type));
        
        if (invalidFiles.length > 0) {
            setFileError('Niektoré súbory majú nepodporovaný formát. Prosím, vyberte iba fotky alebo videá.');
            setFiles([]);
            setTotalSize(0);
            setIsSubmitDisabled(true); // Disable submit button
        } else {
            setFileError('');  // Clear any previous error
            setFiles(selectedFiles);
            const size = selectedFiles.reduce((sum, file) => sum + file.size, 0);
            setTotalSize(size);
            setIsSubmitDisabled(selectedFiles.length === 0); // Enable submit if valid files are selected
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'AM Wedding',
                text: '29.08.2024',
                url: window.location.href
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="AM Wedding">
                <header>
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main className='password'>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Heslo"
                        />
                        <Button type="submit">Odoslať</Button>
                    </form>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                </main>
            </div>
        );
    }

    return (
        <div className="AM Wedding">
            <title>A&M | 29.08.2024</title>
            <header>
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <main className='upload'>
                <h1>Vitajte na našej svadbe!</h1>
                <div className='formContainer'>
                    <Form onSubmit={handleUpload}>
                        <FileInput id="fileInput" type="file" multiple onChange={handleFileChange} accept='image/*,video/*' />
                        <Button type="submit" disabled={isSubmitDisabled}>Odoslať fotky/videá</Button>
                    </Form>
                    {fileError && <p className="error">{fileError}</p>}
                    <InlineFlex>
                        {totalSize > 0 && (
                            <p className='small'>Veľkosť: {(totalSize / 1024 / 1024).toFixed(2)} MB</p>
                        )}
                    </InlineFlex>
                    {totalProgress > 0 && (
                        <div className="progress-container">
                            <div className="progress-bar" style={{ width: `${totalProgress}%` }}>
                                {totalProgress}%
                            </div>
                        </div>
                    )}
                    <Link to="/photo-gallery">
                        <GalleryButton>Galéria</GalleryButton>
                    </Link>
                </div>
            </main>
            <footer>
                <QRCode value={window.location.href} />
                <Button onClick={handleShare}>Zdielať</Button>
            </footer>

            {showSuccessPopup && (
                <Modal className="modal" onClose={() => setShowSuccessPopup(false)}>
                    <ModalContent className="modalContent">
                        <h2>Ďakujeme!</h2>
                        <p>Všetky fotky boli úspešne odoslané!</p>
                        <Button onClick={() => setShowSuccessPopup(false)}>OK</Button>
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
}

export default App;
