import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './App.css';
import { ImageGrid, Modal, ModalContent, CloseButton, ImagePreview, HeaderButton, Video, Image, Button, ImageContainer, GalleryButton, AlertButton, RedButton } from './Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import logo from './am-logo.svg';

const PhotoGallery = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [unauthorized, setUnauthorized] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [isDownloadingSelected, setIsDownloadingSelected] = useState(false);
  const [isDeletingSelected, setIsDeletingSelected] = useState(false);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get('https://backend.am.markoshub.com:1337/api/protected');
        setIsAdmin(response.data.role === 'admin');
      } catch (error) {
        setIsAdmin(false);
      }
    };
    checkAdmin();
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backend.am.markoshub.com:1337/api/photos', {
        withCredentials: true,
      });
      setMediaItems(response.data.mediaItems);
      const totalSizeResponse = await axios.get('https://backend.am.markoshub.com:1337/api/photos/size', {
        withCredentials: true,
      });
      setTotalSize(totalSizeResponse.data.totalSize);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setUnauthorized(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSelectedMedia = async () => {
    if (selectedMedia.length === 0) return;

    setIsDeletingSelected(true);

    try {
      await axios.delete('https://backend.am.markoshub.com:1337/api/delete-file', {
        data: { selectedMedia },
        withCredentials: true,
      });

      setMediaItems((prev) => prev.filter((item) => !selectedMedia.some((media) => media.fullSize === item.fullSize)));
      setSelectedMedia([]);
    } catch (error) {
      console.error('Failed to delete selected media:', error);
    } finally {
      setIsDeletingSelected(false);
    }
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedMediaUrl(null);
    setSelectedMediaType(null);
  };

  const openMedia = (url, type) => {
    setSelectedMediaUrl(url);
    setSelectedMediaType(type);
    setShowImageModal(true);
  };

  const toggleSelectMode = () => setSelectMode(!selectMode);

  const handleSelectMedia = (media) => {
    setSelectedMedia(prevSelected =>
      prevSelected.includes(media)
        ? prevSelected.filter(item => item !== media)
        : [...prevSelected, media]
    );
  };

  const handleDownloadSelectedMedia = async () => {
    if (selectedMedia.length === 0) return;

    setIsDownloadingSelected(true);

    try {
      const response = await axios.post('https://backend.am.markoshub.com:1337/api/download-selected-photos', {
        selectedMedia
      }, {
        responseType: 'blob',
        withCredentials: true,
      });

      const blob = new Blob([response.data], { type: 'application/zip' });
      saveAs(blob, 'a&m_vyber.zip');
    } catch (error) {
      console.error('Error downloading selected media:', error);
    } finally {
      setIsDownloadingSelected(false);
    }
  };

  const handleDownloadAllPhotos = async () => {
    setIsDownloadingAll(true);

    try {
      const response = await axios.get('https://backend.am.markoshub.com:1337/api/download-all-photos', {
        responseType: 'blob',
        withCredentials: true,
      });

      const blob = new Blob([response.data], { type: 'application/zip' });
      saveAs(blob, 'a&m_vsetky.zip');
    } catch (error) {
      console.error('Error downloading all media:', error);
    } finally {
      setIsDownloadingAll(false);
    }
  };

  return (
    <div>
      <header>
        <HeaderButton onClick={() => navigate('/')} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
          {' '}Back
        </HeaderButton>
        <img src={logo} className="A&M" alt="logo" onClick={() => navigate('/')} />
      </header>
      <div className="photo-gallery">
        {mediaItems.length === 0 ? (
          unauthorized ? (
            <p>
              Musíte byť prihlásený na prezeranie fotiek.{' '}
              <button onClick={() => navigate('/')} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 0 }}>
                Kliknite sem pre prihlásenie.
              </button>
            </p>
          ) : (
            <p>Zatiaľ tu nič nie je...</p>
          )
        ) : (
          <div>
            <div className="toolbar">
              <GalleryButton onClick={toggleSelectMode}>
                {selectMode ? 'Zrušiť výber' : 'Režim výberu'}
              </GalleryButton>
              <Button onClick={handleDownloadAllPhotos} disabled={isDownloadingAll}>
                <FontAwesomeIcon icon={faDownload} />{' '}
                {isDownloadingAll ? 'Sťahovanie...' : 'Stiahnuť všetky fotky'}
              </Button>
              {selectMode && (
                <>
                  <Button onClick={handleDownloadSelectedMedia} disabled={selectedMedia.length === 0 || isDownloadingSelected}>
                    <FontAwesomeIcon icon={faDownload} />{' '}
                    {isDownloadingSelected ? 'Sťahovanie...' : `Stiahnuť vybrané fotky (${selectedMedia.length})`}
                  </Button>
                  {isAdmin && (
                    <RedButton onClick={handleDeleteSelectedMedia} disabled={selectedMedia.length === 0 || isDeletingSelected}>
                      <FontAwesomeIcon icon={faTrash} />{' '}
                      {isDeletingSelected ? 'Mazanie...' : `Vymazať vybrané (${selectedMedia.length})`}
                    </RedButton>
                  )}
                </>
              )}
            </div>
            <ImageGrid>
              {mediaItems.map((item, index) => (
                <ImageContainer
                  key={index}
                  className={`media-item ${selectMode && selectedMedia.includes(item) ? 'selected' : ''}`}
                  onClick={() => selectMode && handleSelectMedia(item)}
                >
                  {item.type === 'photo' ? (
                    <LazyLoadImage
                      width="100%"
                      height="100%"
                      effect="blur"
                      src={`https://backend.am.markoshub.com:1337${item.thumbnail}`}
                      alt="Gallery"
                      onClick={() => !selectMode && openMedia(`https://backend.am.markoshub.com:1337${item.fullSize}`, 'photo')}
                      className={`image-style ${selectMode && selectedMedia.includes(item) ? 'selected-image' : ''}`}
                      style={{ opacity: (selectMode && !selectedMedia.includes(item)) ? 0.5 : 1 }}
                    />
                  ) : (
                    <LazyLoadComponent>
                      <div className="media-container">
                        <Video
                          src={`https://backend.am.markoshub.com:1337${item.fullSize}#t=0.001`}
                          controls={!selectMode}
                          className={`image-style ${selectMode && selectedMedia.includes(item) ? 'selected-image' : ''} ${selectMode ? 'video-select-mode' : ''}`}
                          style={{
                            opacity: (selectMode && !selectedMedia.includes(item)) ? 0.5 : 1,
                            pointerEvents: selectMode ? 'none' : 'auto',
                            transition: 'opacity 0.3s ease'
                          }} />
                        {selectMode && !selectedMedia.includes(item) && (
                          <div className="overlay">
                            <span className="overlay-text">Video sa nedá prehrať v režime výberu</span>
                          </div>
                        )}
                      </div>
                    </LazyLoadComponent>
                  )}
                </ImageContainer>
              ))}
            </ImageGrid>
            <p>Ceľková veľkosť: {Math.round(totalSize / (1024 * 1024))} MB</p>
            {loading && <p>Loading...</p>}
          </div>
        )}
        {showImageModal && (
          <Modal onClose={handleCloseImageModal}>
            <ModalContent>
              <CloseButton onClick={handleCloseImageModal}>
                <FontAwesomeIcon icon={faTimes} />
              </CloseButton>
              {selectedMediaType === 'photo' ? (
                <ImagePreview
                  src={selectedMediaUrl}
                  alt="Selected"
                />
              ) : (
                <Video src={selectedMediaUrl} controls autoPlay />
              )}
            </ModalContent>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default PhotoGallery;
