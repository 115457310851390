import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PhotoUpload from './PhotoUpload';
import PhotoGallery from './PhotoGallery';
import './index.css'; // Make sure this file exists and contains your global styles

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<PhotoUpload />} />
      <Route path="/photo-gallery" element={<PhotoGallery />} />
    </Routes>
  </Router>
);
